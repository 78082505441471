// PayLaterButton.js
import React, { useState } from 'react';
import Button from './button';
import BillFinancingModal from './bill-financing-modal';

/**
 * Accept the already-populated financingRequest from bill.financingData.financingRequest
 * Accept the overall user's creditApplication as a prop.
 */
export default function PayLaterButton({
  bill,
  creditApplication = null,
  preload = false,
  className = ""
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [billData, setBillData] = useState(bill);
  // We'll store the existing financingRequest from the bill
  const [financingRequest, setFinancingRequest] = useState(
    bill?.financingData?.financingRequest || null
  );
  const [loading, setLoading] = useState(false);

  // Whether there's an existing financingRequest
  const hasFinancingRequest = Boolean(financingRequest);

  // Check if there's any non-approved offer
  const hasPendingOffers = financingRequest?.offers?.length > 0 &&
    financingRequest.status !== 'Approved';

  const handleClick = () => {
    setLoading(true);

    // Validate the bill's data + credit app
    const isBillDataValid = Boolean(
      bill?.invoiceNumber &&
      bill?.subtotal &&
      bill?.project &&
      bill?.document &&
      (bill?.taxExempt || bill?.tax === 0 || bill?.tax)
    );
    const isCreditApplicationValid = creditApplication?.status === 'verified';

    setLoading(false);

    if (!isBillDataValid || !isCreditApplicationValid) {
      setCurrentStep(1); // Validation step
    } else if (financingRequest?.status === 'Approved') {
      setCurrentStep(6);
    } else if (financingRequest) {
      setCurrentStep(4);
    } else {
      setCurrentStep(2);
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={`relative inline-block ${className}`}>
        <Button
          onClick={handleClick}
          className={`text-xs whitespace-nowrap`}
          text={hasFinancingRequest ? 'See Offers' : 'Pay Later'}
        />
        {hasPendingOffers && (
          <span className="absolute -top-1 -right-1 block w-3 h-3 bg-red-600 rounded-full"></span>
        )}
      </div>
      {isOpen && (
        <BillFinancingModal
          billData={billData}
          onClose={closeModal}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setBillData={setBillData}
          creditApplication={creditApplication}
          setCreditApplication={() => null} // not used in our simplified approach
          loading={loading}
          setLoading={setLoading}
          financingRequest={financingRequest}
          setFinancingRequest={setFinancingRequest}
          project={billData?.project}
        />
      )}
    </>
  );
}