import ReactInputMask from 'react-input-mask';

export default function PhoneField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  placeholder,
  onBlur,
  type,
  required,
  disabled = false,
}) {
  // Determine the number of digits (ignoring non-digits)
  const digitCount = value ? value.replace(/\D/g, '').length : 0;
  // Use the mask only if there are 10 or fewer digits; otherwise, allow the full pasted number
  const mask = digitCount > 10 ? undefined : "999-999-9999";

  const handleChange = (e) => {
    let newValue = e.target.value;
    // Remove leading +1 if present
    if (newValue.startsWith('+1')) {
      newValue = newValue.replace('+1', '');
    }
    onChange(newValue);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let paste = e.clipboardData.getData('Text');
    // Remove leading +1 if pasted
    if (paste.startsWith('+1')) {
      paste = paste.replace('+1', '');
    }
    // Remove non-digit characters
    paste = paste.replace(/\D/g, '');
    onChange(paste);
  };

  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="mt-1">
        <ReactInputMask
          mask={mask}
          value={value}
          onChange={handleChange}
          onPaste={handlePaste}
          disabled={disabled}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              required={required}
              type={type ? type : 'text'}
              onBlur={onBlur}
              placeholder={placeholder}
              className={`block w-full border px-3 p-2 text-gray-800 shadow placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass} bg-primary ${disabled ? 'bg-gray-100' : ''}`}
            />
          )}
        </ReactInputMask>
      </div>
    </div>
  );
}